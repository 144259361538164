var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_vm.trust ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center mb-4",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiatasas.witness-desc")) + " ")]), _c('form', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "table-responsive mt-3 mb-4"
  }, [_c('table', {
    staticClass: "table table-sm"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("fullname")))]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("action")) + " ")])])]), _c('tbody', [_vm._l(_vm.witnesses, function (witness, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(witness.name))]), _c('td', [_vm._v(_vm._s(witness.ic_number))]), _c('td', [_vm.userRole == 'Staff' ? _c('b-button', {
      staticClass: "btn-sm btn-danger",
      on: {
        "click": function ($event) {
          return _vm.deleteWitness(witness, index);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("remove")))]) : _vm._e()], 1)]);
  }), _vm.witnesses && _vm.witnesses.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_c('h5', {
    staticClass: "mt-5"
  }, [_vm._v(_vm._s(_vm.$t("wasiatasas.witness2")))]), _vm.userRole == 'Client' ? _c('p', [_c('small', [_c('i', [_vm._v(" " + _vm._s(_vm.$t("wasiatasas.witness3")) + " ")])])]) : _vm._e()])]) : _vm._e()], 2)])])]), _vm.witnesses && _vm.witnesses.length < 4 && _vm.userRole == 'Staff' ? _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('a', {
    staticClass: "btn btn-primary btn-sm",
    on: {
      "click": _vm.openSaksiModal
    }
  }, [_vm._v(_vm._s(_vm.$t("add-witness")))])])]) : _vm._e()])]), _c('b-modal', {
    ref: "modalsaksi",
    staticClass: "modal fade",
    attrs: {
      "id": "modal-1",
      "title": _vm.$t('add-witness'),
      "ok-title": _vm.$t('save'),
      "cancel-title": _vm.$t('cancel'),
      "no-enforce-focus": true
    },
    on: {
      "ok": _vm.addSaksi,
      "cancel": _vm.hideModalSaksi
    }
  }, [_c('div', {
    staticClass: "card-body",
    attrs: {
      "id": "divCard"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "current_user"
    }
  }, [_vm._v(_vm._s(_vm.$t("witnessmodal.title")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.currentIndex,
      expression: "form.currentIndex"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "current_user"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "currentIndex", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.fillSaksiForm]
    }
  }, [_c('option', {
    attrs: {
      "value": "-2"
    }
  }), _c('option', {
    attrs: {
      "value": "-1"
    }
  }, [_vm._v(" -- " + _vm._s(_vm.$t("witnessmodal.add-witness")) + " -- ")]), _vm._l(_vm.formSaksi, function (accessible, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": index
      }
    }, [_vm._v(" " + _vm._s(accessible.user.name) + " (" + _vm._s(accessible.accessible_type) + ") ")]);
  })], 2)]), _vm.form.currentIndex >= -1 ? _c('div', [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("fullname")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.form.name,
      expression: "form.name",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "name", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('the-mask', {
    staticClass: "form-control",
    attrs: {
      "id": "ic_number",
      "mask": ['######-##-####']
    },
    model: {
      value: _vm.form.ic_number,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ic_number", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.ic_number"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("E-mel")]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('the-mask', {
    staticClass: "form-control",
    attrs: {
      "id": "email"
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('div', {
    staticClass: "form-group mb-1"
  }, [_c('label', {
    attrs: {
      "for": "exampleTextarea"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("address")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.form.address,
      expression: "form.address",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "rows": "2",
      "value": ""
    },
    domProps: {
      "value": _vm.form.address
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "address", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "postcode"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy.trim",
      value: _vm.postcode,
      expression: "postcode",
      modifiers: {
        "lazy": true,
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "postcode"
    },
    domProps: {
      "value": _vm.postcode
    },
    on: {
      "blur": [_vm.getPostcode, function ($event) {
        return _vm.$forceUpdate();
      }],
      "change": function ($event) {
        _vm.postcode = $event.target.value.trim();
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "exampleSelect1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("city")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.city,
      expression: "form.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "city", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "exampleSelect1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("state")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.state,
      expression: "form.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "state", $event.target.value);
      }
    }
  })])])])]) : _vm._e()]), _c('div', {
    staticClass: "card-body"
  }, [_vm.formSaksi.newWitness ? _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "exampleSelect1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("profile.signature")) + " ")]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('div', {
    staticClass: "card",
    staticStyle: {
      "width": "100%",
      "display": "inline-block"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "relative",
      "height": "300px",
      "width": "100%"
    }
  }, [_c('VueSignaturePad', {
    ref: "signaturePad",
    staticStyle: {
      "position": "absolute",
      "z-index": "1"
    },
    attrs: {
      "height": "300px",
      "width": "100%",
      "options": {
        onBegin: function () {
          _vm.$refs.signaturePad.resizeCanvas();
        }
      }
    }
  }), _c('label', {
    staticStyle: {
      "z-index": "0",
      "position": "absolute",
      "right": "0px",
      "left": "0px",
      "top": "45%",
      "color": "#b6b6b4",
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign-here")) + " ")]), _c('a', {
    staticClass: "btn btn-primary",
    staticStyle: {
      "position": "absolute",
      "right": "5px",
      "top": "5px",
      "z-index": "2"
    },
    on: {
      "click": _vm.undo
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/rubber.svg",
      "height": "20",
      "alt": ""
    }
  })])], 1)])]) : _vm._e()])])], 1)])]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('b-button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.outpage
    }
  }, [_vm._v("Keluar")])], 1)])]), _c('b-modal', {
    ref: "formAddPostcode",
    staticClass: "modal fade",
    attrs: {
      "id": "formAddPostcode",
      "hide-footer": ""
    }
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_c('h5', {
    staticClass: "modal-title",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("add-postcode")) + " ")])]), _c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("postcode")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control savePostcode",
    attrs: {
      "type": "number",
      "name": "userstate"
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("city")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control saveCity",
    attrs: {
      "type": "text",
      "name": "userstate"
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("state")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control saveState",
    attrs: {
      "type": "text",
      "name": "userstate"
    }
  })])]), _c('b-button', {
    staticClass: "mt-3",
    attrs: {
      "variant": "outline-danger",
      "block": ""
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(_vm._s(_vm.$t("close")))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }